import axios from "axios"

const API_URL = process.env.REACT_APP_BACK_END_END_POINT;

// Helper function to handle errors
function handleError(error) {

    // Handle server error
    if (error.response) {
        // console.error('Server responded with an error:', error.response.data);
        throw new Error(error.response.data.error);
    }
    // handle network error
    else if (error.request) {
        // console.error('No response received:', error.request);
        throw new Error('Check your internet connection');
    }
    // handle any other error
    else {
        // console.error('Error in setting up the request:', error.message);
        throw new Error(error.message);
    }
}

// get token
export async function getCsrfToken() {
    try {
        const response = await axios.get(`${API_URL}/get-csrf-token/`, {
            withCredentials: true,
        });
        return response.data.csrfToken;
    }
    catch (error) {
        handleError(error);
    }
}

//create report
export async function createReport(addr) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        const response = await axios.post(`${API_URL}/reports/`,
            {
                address: addr
            }
        )
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}



export async function getUserChatHistory() {
    try {
        const response = await axios.get(`${API_URL}/chats/`, { withCredentials: true });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}


export async function getChatById(chatId) {
    try {
        const response = await axios.get(`${API_URL}/chats/${chatId}/`, { withCredentials: true });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

export async function createChat(title, msg, address) {
    try {

        // console.log(title, msg, address)
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        const response = await axios.post(`${API_URL}/messages/create/`,
            {
                "chat_title": title,
                "sent_by": "User",
                "text": msg,
                "chat_address": address
            }
        )
        return response.data;

    }
    catch (error) {
        handleError(error);
    }



}

export async function nextChat(chatId, client, msg, address) {
    try {

        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        
        if(address){
            const response = await axios.post(`${API_URL}/messages/create/`,
                {
                    "chat_id": chatId,
                    "sent_by": client,
                    "text": msg,
                    "chat_address": address
                },
                { withCredentials: true }
            )

            return response.data;
        }
        else{
            const response = await axios.post(`${API_URL}/messages/create/`,
                {
                    "chat_id": chatId,
                    "sent_by": client,
                    "text": msg,
                },
                { withCredentials: true }
            )

            return response.data;
        }

    }
    catch (error) {
        handleError(error);
    }


}


export async function getParcelInfo(addr, reportId) {
    try {

        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        const response = await axios.post(`${API_URL}/zoning/`,
            {
                address: addr,
                report_id: reportId

            },
        )
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}


export async function getComparableSales(addr, reportId, planId) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        const response = await axios.post(`${API_URL}/comparable-sales/`,
            {
                address: addr,
                report_id: reportId,
                architectural_plan: planId

            },
        )
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}



export async function getPersonalInfo() {
    try {
        const response = await axios.get(`${API_URL}/user-info/`, { withCredentials: true });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

export async function getArchitecturePlansOnDashboard(body, reportId) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        const requestBody = {
            ...body,
            report_id: reportId
        };
        const response = await axios.post(`${API_URL}/architectural_plans/`, requestBody );
        return response.data;
    }
    catch (error) {
        handleError(error);
    }

}

// Get all favorite plans
export async function getFavoritePlans() {
    try {
        const response = await axios.get(`${API_URL}/favorites/`, { withCredentials: true });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }

}


export async function setFavoritePlan(planId) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        await axios.post(`${API_URL}/favorites/new/`, { "architectural_plan": planId });
    }
    catch (error) {
        handleError(error);
    }

}

// delete favorite
export async function deleteFavoritePlan(planId) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.delete['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        await axios.delete(`${API_URL}/favorites/remove/`, { data: { "architectural_plan": planId } });
    }
    catch (error) {
        handleError(error);
    }

}


// call kpi
export async function getKpi(report_id, build_quality, basement_type, wall_type, land_acquisition_cost, address, architectural_plan_id) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        const response = await axios.post(`${API_URL}/kpis/`,
            {
                "report_id": report_id,
                "build_quality": build_quality,
                "basement_type": basement_type,
                "walltype": wall_type,
                "land_acquisition_cost": land_acquisition_cost,
                "address": address,
                "architectural_plan_id": architectural_plan_id
            }
        );
        // console.log(response.data);
        return response.data;
    }
    catch (error) {
        handleError(error);
    }

}


//get reports
export async function getReports() {
    try {
        const response = await axios.get(`${API_URL}/reports/`, { withCredentials: true });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

//get a report
export async function getReport(reportId) {
    try {
        const response = await axios.get(`${API_URL}/reports/${reportId}/`, { withCredentials: true });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

//send report to email
export async function sendReportToEmail(reportId, email) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        await axios.post(`${API_URL}/reports/${reportId}/send-email/`, {
            "email": email
        });
    }
    catch (error) {
        handleError(error);
    }
}


// get single chat address
export async function getAddressOfChat(chatid){
    try{
        const response = await axios.get(`${API_URL}/chats/address/${chatid}/`, { withCredentials: true });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

// get chat exists
export async function getChatIdIfExists(address){
    
    try{
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        const response = await axios.post(`${API_URL}/chats/check/`,
            {
                "chat_address" : address
            },
        );
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

//change password
export async function changePassword(new_password) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.patch['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        await axios.patch(`${API_URL}/change-password/`, {
            "new_password": new_password
        });
        window.location.href = "/login";
    }
    catch (error) {
        handleError(error);
    }
}


// llm request method post
export async function llmRequest(data) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        const response = await axios.post(`${API_URL}/get_response_from_LLM/`,
            data
        );
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}


// forgot password
export async function forgotPassword(email) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        await axios.post(`${API_URL}/forgot-password/`, {
            "email": email
        });
    }
    catch (error) {
        handleError(error);
    }
}

// reset password
export async function resetPassword(new_password, token, uidb64) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        await axios.post(`${API_URL}/reset-password/`, {
            "new_password": new_password,
            "token": token,
            "uidb64":uidb64
        });
    }
    catch (error) {
        handleError(error);
    }
}

// resend otp
export async function resendLoginOtp(email) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        await axios.post(`${API_URL}/resend-login-otp/`, {
            "username": email
        });
    }
    catch (error) {
        handleError(error);
    }
}

//resend verify otp
export async function resendVerifyOtp(email) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        await axios.post(`${API_URL}/resend-verify-email-otp/`, {
            "username": email
        });
    }
    catch (error) {
        handleError(error);
    }
}


//upload profile picture
export async function uploadProfilePicture(formData) {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        axios.defaults.withCredentials = true;
        const response = await axios.post(`${API_URL}/upload-profile-picture/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
              },
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

// get profile picture
export async function getProfilePicture() {
    try {
        const response = await axios.get(`${API_URL}/profile-picture/`, { withCredentials: true });
        return response.data;
    }
    catch (error) {
        return {presigned_url: "/images/default_profile.png", error: error.response.data.error}
    }
}

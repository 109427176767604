import React, { useContext } from 'react'
import './LanHome.css'
import NavBar from '../../components/navbar/NavBar'
import { ArrowRightAltRounded, AttachMoneyOutlined, BoltOutlined, CheckOutlined, SearchOutlined } from '@mui/icons-material'
import LanReviews from './components/lan_reviews/LanReviews'
import ActionSection from './components/action_section/ActionSection'
import JoinIqlandToday from './components/join_iqland_today/JoinIqlandToday'
import Footer from '../../components/footer/Footer'
import { useNavigate } from 'react-router-dom'
import FaqComp from './components/faq_comp/FaqComp'
import { faqData } from './data/FaqData'
import { AuthContext } from '../../components/auth/AuthenticationContext'
import { LoadingSkeletonText } from '../../components/skeleton_loading/SkeletonLoading'
import { customerReviewData } from './data/CustomerReviewsData'

function LanHome() {

  const navigate = useNavigate();

  const { user, loading } = useContext(AuthContext);

  return (
    <div className='lan_home'>
      <NavBar />
      <div className='land_home__hero_new'>
        <div className='land_home__hero_left_new'>
          <h1 className='lan_title' style={{textTransform:"uppercase"}}>Building a home is hard. IQLAND makes it easy.</h1>
          <p className='lan_sub_title'>IQLAND's Copilot is your AI-enhanced guide, simplifying land development by answering key questions on construction costs, zoning regulations and more!</p>
          {
            !loading ?
              <div
                className='lan_btn_white'
                onClick={() => user ? navigate("/copilot") : navigate("/register")}>
                Get Started
              </div>
              :
              <LoadingSkeletonText width={"10rem"} height={"2.5rem"} />
          }
        </div>
        <div className='land_home__hero_right_new'>
          <img src='/images/lan_home/realestate_dev_1.png' alt='home' />
        </div>
      </div>
      {/* <div className='lan_logo_list'> */}
        {/* {
          [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
            <div className='lan_logo_list_item' key={index}>
              <img src='/images/lan_home/la_gg_circle.svg' alt='logo' />
              <div>BUILDING</div>
            </div>
          ))
        } */}
      {/* </div> */}

      <div className='lan_home__motto_area' style={{backgroundColor:"rgb(233, 255, 246)"}}>
        <h1 className='iqland_motto'>Land intelligence done. <span>Fast.</span></h1>
        <p className='lan_normal_text'>IQLAND is your copilot for real estate development, allowing developers, agents and investors to evaluate projects more efficiently.</p>
        <div className='lan_home__motto_area_action'>
          <div className='lan_free_trial_text'>Start your 7-day free trial</div>
          <ArrowRightAltRounded />
          {
            !loading ?
              <div
                className='lan_primary_button'
                onClick={() => user ? navigate("/copilot") : navigate("/register")}>
                Get Started
              </div>
              :
              <LoadingSkeletonText width={"10rem"} height={"2.5rem"} />
          }
        </div>
      </div>

      <div className='land_home__copilot' style={{ padding: "2rem 3rem", marginTop: "2rem" }}>
        <div className='iqland_motto__left'>
          <img src='/images/lan_home/copilot_1.png' alt='copilot' />
        </div>
        <div className='iqland_motto__right'>
          <h1 className='iqland_motto'>Copilot for Land Development</h1>
          <p className='lan_normal_text'>IQLAND's Copilot is your AI-enhanced guide, simplifying land development by answering key questions on construction costs, project value, and optimal land use with ease. </p>
          <div className='lan_check_list'>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p>Construction cost estimates</p></div>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p>Project value assessments</p></div>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p>Optimal land use recommendations</p></div>
          </div>
          <a href='/products/copilot' style={{ textDecoration: "none" }}><div className='lan_btn_green'>Learn More</div></a>
        </div>
      </div>

      <div className='land_home__dashboard' style={{ padding: "2rem 3rem", marginTop: "2rem", backgroundColor: "#F2FFFA" }}>

        <div className='iqland_motto__right'>
          <h1 className='iqland_motto'>Dashboard</h1>
          <p className='lan_normal_text'>IQLAND's Dashboard simplifies development with tools that pair you with optimal architectural plans, estimate the cost of your projects, and analyze zoning regulations for you.</p>
          <div className='lan_check_list'>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p>Profitability estimation</p></div>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p>Visualize parcel and zoning data</p></div>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p>Tailored architectural plans recommendation</p></div>
          </div>
          <a href='/products/dashboard' style={{ textDecoration: "none" }}><div className='lan_btn_green'>Learn More</div></a>
        </div>
        <div className='iqland_motto__left'>
          <img src='/images/lan_home/dashboard_1.png' alt='copilot' />
        </div>
      </div>

      <div className='lan_home__why_iqland'>
        <div className='lan_home__why_iqland_top'>
          <div className='lan_sub_title_2'>Why <span>IQLAND?</span></div>
          {/* <p className='lan_normal_text'>IQLAND's Copilot is your AI-enhanced guide, simplifying land development by answering key questions</p> */}
        </div>
        <div className='lan_home__why_iqland_cards'>
          <div className='lan_home__why_iqland_card'>
            <BoltOutlined sx={{ color: "var(--secondary-clr)", backgroundColor: "var(--primary-clr)", borderRadius: "50%", width: "4rem", height: "4rem", padding: "1rem" }} />
            <div className='lan_card__title'>Instant Feasibility</div>
            <p className='lan_normal_text'>IQLAND's AI-driven instant feasibility assessments quickly determine project viability. Make informed decisions early, ensuring your development vision aligns with market demands.</p>
          </div>
          <div className='lan_home__why_iqland_card'>
            <AttachMoneyOutlined sx={{ color: "var(--secondary-clr)", backgroundColor: "var(--primary-clr)", borderRadius: "50%", width: "4rem", height: "4rem", padding: "1rem" }} />
            <div className='lan_card__title'>Cost Estimations</div>
            <p className='lan_normal_text'>IQLAND's precise construction cost estimations offer accurate budget forecasts. Manage resources efficiently and stay within budget, ensuring successful project execution.</p>
          </div>
          <div className='lan_home__why_iqland_card'>
            <SearchOutlined sx={{ color: "var(--secondary-clr)", backgroundColor: "var(--primary-clr)", borderRadius: "50%", width: "4rem", height: "4rem", padding: "1rem" }} />
            <div className='lan_card__title'>Profit Analysis</div>
            <p className='lan_normal_text'>IQLAND's dynamic profitability analysis provides AI-powered insights for optimal decision-making. Evaluate and adjust project profitability to turn your development vision into reality.</p>
          </div>
        </div>
      </div>

      <div className='lan_home__solution'>
        <div className='lan_home__solution__left'>
          <div className='lan_title' style={{ color: "white" }}>Solution</div>
          <div className='lan_gold_subtitle'>Real Estate Developers</div>
          <p className='lan_normal_text' style={{ color: "white" }}>IQLAND revolutionizes real estate development by leveraging AI to offer instant feasibility assessments, precise construction cost estimations, and dynamic profitability analysis, streamlining the path to project success. Discover a world where potential is clearly defined and your vision for development is realized with unmatched efficiency.</p>
          <a href='/solutions/developers' style={{ textDecoration: "none" }}><div className='lan_btn_gold' onClick={() => navigate("/solutions/developers")}>Explore More</div></a>
        </div>
        <div className='lan_home__solution__right'>
          <img src='/images/lan_home/architect_1.png' alt='architect' />
        </div>
      </div>

      <div className='lan_faqs'>
        <div className='lan_title' style={{ color: "var(--primary-clr)" }}>FAQs</div>
        <FaqComp data={faqData} />
      </div>

      <div className='lan_reviews' style={{ marginBottom: "2rem" }}>
        <div className='iqland_motto' style={{ textAlign: "center" }}>What our customers <span>say</span></div>
        <div className='lan_review_cards'>
          {
            customerReviewData.map((item, index) => (
              <LanReviews key={index} comments={item.review} cusName={item.name} job={item.job} image={item.profile}/>
            ))
          }
        </div>
      </div>

      <ActionSection />
      <JoinIqlandToday />
      <Footer />

    </div>
  )
}

export default LanHome